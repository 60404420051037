<template>
  <app-dialog :subtitle="creationDateLabel" :title="title" :width="600">
    <p v-if="rating.comment" class="mb-8 text-subtle">“{{ rating.comment }}”</p>

    <section class="mb-8">
      <h3 class="mb-3 font-semibold">Satisfaction</h3>

      <p class="mb-4 flex items-center gap-2">
        <app-rating-label :value="rating.global_satisfaction" />
        <app-rating-stars :value="rating.global_satisfaction" />
      </p>
    </section>

    <section>
      <h3 class="mb-4 font-semibold">Autres notes</h3>
      <rating-details-table descriptions :details="rating" />
    </section>
  </app-dialog>
</template>

<script lang="ts" setup>
const properties = defineProps<{
  rating: DatabaseTable<"course_session_ratings"> & {
    author: Pick<DatabaseTable<"users">, "full_name_abbreviated">;
  };
}>();

const title = computed(
  () => `Détail de l'avis de ${properties.rating.author.full_name_abbreviated}`,
);

const creationDateLabel = computed(() => {
  return `déposé le ${useI18nDate(properties.rating.created_at).value}`;
});
</script>
